.homeContainer {
	margin: 0 auto;
}
.top-content-text {
	font-size: 16px;
	font-weight: bold;
	width: 90%;
}
.homeContent p {
	font-size: 18px;
	line-height: 24px;
	margin: 12px 0;
}
.homeContent {
	margin-bottom: 35px;
}
.homeContent .alert-text {
	font-size: 15px;
}
