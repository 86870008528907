a.downloadLink {
	color: #000;
	text-decoration: unset;
	line-height: 2em;
}

.highlightedOption {
	padding: 7px 20px;
	background: #ccc;
	border-radius: 17px;
	margin-left: 10px;
	font-size: 14px;
	font-weight: 600;
}

.downloadSection a.downloadLink {
	padding: 6px 20px;
	background: #cccccc87;
	border-radius: 17px;
	margin-right: 10px;
	font-size: 14px;
}
.downloadTableSection {
	margin-top: 3em;
}
.downloadTable th:first-child {
	overflow-wrap: break-word;
	width: 1em;
}
