.footerWrapper {
	background-color: #e6e6e6;
	height: 30px;
	width: 100%;
	border-top: 0.5mm solid;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	position: fixed;
	bottom: 0;
}
.footerRightpart {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 20px;
}
.verticalLine {
	border-left: 1px solid #000;
	margin: 5px 10px;
	height: 15px;
}
.footerLeftpart {
	display: flex;
	align-items: center;
}
.footerLeftpart span {
	margin-left: 20px;
	font-size: 10px;
	color: black;
}
.footerRightpart a {
	font-size: 10px;
	color: black;
	text-decoration: none;
}
