body,
p {
	font-family: Arial, Helvetica, sans-serif;
}
body {
	background-color: #cccccc14;
}
.red-text {
	color: red;
}
.content {
	width: 76%;
	float: right;
	margin: 5em auto 0 auto;
}

.error-text {
	margin-bottom: 2em;
	font-size: 12px;
	text-align: left;
	color: #9f0002;
	font-weight: 600;
}
.error {
	margin-bottom: 2em;
	font-size: 12px;
	text-align: left;
	color: red;
	font-weight: 600;
}
.required:after {
	content: " *";
	color: red;
	font-size: 1rem;
  }