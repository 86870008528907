.searchInput {
	width: 40%;
	float: right;
	margin: 2em 0 0;
}
.EditResellerWrapper {
	margin-right: 2em;
	margin-top: 2em;
}
.searchInputWrapper {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width: 100%;
}
