.ExportDataWrapper {
	margin-right: 2em;
	margin-top: 2em;
	margin-bottom: 4em;
}
.ExportDataWrapper .countryFileFormatWrapper {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: 1em;
	margin-top: 2em;
}
.checkBoxText {
	display: flex;
	align-items: center;
}
.bottomSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
