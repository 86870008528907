.DataReviewWrapper {
	margin-right: 2em;
	margin-top: 2em;
	margin-bottom: 4em;
}
.DataReviewWrapper .countryFileFormatWrapper {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1em;
	margin-top: 2em;
}
.dataReviewText {
	font-size: 13px;
	margin-top: 0;
	line-height: 20px;
}
.checkBoxBlock {
	display: flex;
	align-items: baseline;
}
.checkBoxBtn .MuiCheckbox-root {
	padding-bottom: 0;
}
