.addNewResellerWrapper {
	margin-right: 2em;
	margin-top: 2em;
}
.companyDetails .MuiGrid-item,
.address .MuiGrid-item,
.futherInformation .MuiGrid-item {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.fileUpload svg {
	margin-left: 2em;
}

.countryCheckBoxWrapper {
	display: flex;
	flex-wrap: wrap;
}

.division {
	width: 100% !important;
	align-items: center;
}
.futherInformation .MuiStack-root {
	padding-top: 0;
}

.fileDetails {
	margin-left: 15px;
}
.fieldTitles {
	height: 1.8rem;
	flex-shrink: 0;
	color: #707070;
	font-size: "0.875rem";
	font-style: "normal";
	font-weight: 400;
	line-height: "1.8rem";
	display: "flex",
}