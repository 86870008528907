.navBarLeft {
	background-color: #e6e6e6;
	width: 20%;
	font-size: 11px;
	color: #000;
	font-weight: 400;
	border-bottom: 0.5mm solid;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1200;
}
.Logo img {
	width: 200px;
	height: 70px;
}
.logo {
	margin-top: 1.5em;
	margin-left: 1.5em;
}
.headerTop {
	display: flex;
	justify-content: space-between;
	margin: 0 10px;
}
.Login {
	border-right: 1px solid #000;
	margin: 0 10px;
	padding-right: 10px;
}
.languageContainer {
	border-right: 1px solid #000;
	margin: 0 10px;
	padding-right: 10px;
}
.headerOptions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0 0;
}
.Logo {
	margin-top: 5px;
}
.userName {
	text-align: right;
	margin-top: 10px;
}
.nav-NavLinks {
	display: flex;
	flex-direction: column;
	padding-left: 10px;
	margin-top: 40px;
}
a.menuItem {
	color: #000;
	text-decoration: none;
	padding: 0 0 0 20px;
	margin-bottom: 15px;
	font-size: 15px;
	font-family: Arial, sans-serif;
}
a.disabledMenuItem{
	color: #bcbcbc;
	text-decoration: none;
	padding: 0 0 0 20px;
	margin-bottom: 15px;
	font-size: 15px;
	font-family: Arial, sans-serif;
}
.headerBottom {
	display: flex;
	justify-content: end;
	margin-bottom: 10px;
}
a.menuItem:last-child {
	border-right: unset;
}
.headerLogo {
	width: 200px;
}
a.menuItem.active {
	text-decoration: underline;
	font-weight: 600;
}
