.HelpWrapper {
	margin-right: 2em;
	margin-top: 2em;
	margin-bottom: 4em;
}
.link {
	color: black;
	text-decoration: none;
}

.link:hover {
	text-decoration: none;
}
