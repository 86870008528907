.kpiReportWrapper {
	margin-right: 2em;
	margin-top: 2em;
}
.countryFileFormatWrapper {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: 1em;
	margin-top: 2em;
}