.maintainContactWrapper {
	margin-right: 2em;
	margin-top: 2em;
}
.countryCheckBoxWrapper {
	display: flex;
	flex-wrap: wrap;
}
.searchContactWrapper {
	margin-right: 2em;
	margin-top: 2em;
	margin-bottom: 4em;
}
.countryFileFormatWrapper {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 1em;
	margin-top: 2em;
}
.gridAlign {
	display: 'flex';
	flex-direction: column;
	justify-content: center;
}
.searchInput {
	width: 40%;
	float: right;
	margin: 2em 0 0;
}
.searchWrapper {
	margin-right: 2em;
	margin-top: 2em;
}
.searchInputWrapper {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}
